












import Vue, { PropType } from 'vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import { defaults } from 'lodash'

/** 获取默认经纬度（深圳） */
export const getDefaultLngLat = () => ({ lng: 108.763066, lat: 34.439958 })

export default Vue.extend({
	props: {
		/** 高德地图key */
		amapKey: {
			type: String,
			default: '210707d8882c5264ac774bd0335f4910',
		},
		lng: {
			type: [Number, String],
			default: 0,
		},
		lat: {
			type: [Number, String],
			default: 0,
		},
		size: {
			type: Object as PropType<{ width: string; height: string }>,
			default() {
				return { width: '100%', height: '500px' }
			},
		},
	},
	// emits: [
	//		'load(AMap)' /** 加载地图完成(AMap) 高德地图 */
	// 		'ready(map)', /** 地图渲染完成 (map) 地图实例 */
	// 		'error(Error)', /** 地图加载出错 (Error) 异常 */
	// 		'change', /** 经纬度变化（通过地图移动或点击选点触发） */
	// 		'select', /** 经纬度变化（通过搜索选择触发） */
	// 		'update:lng', /** 更新精度 */
	// 		'update:lat', /** 更新纬度 */
	// ],
	data() {
		return {
			$map: null as any | null,
			$autoInput: null as any | null,
			$marker: null as any | null,
		}
	},
	methods: {
		/** 加载地图方法，可供外部调用 */
		loadMap(options: any = {}) {
			return AMapLoader.load(
				defaults(options, {
					key: this.amapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
					version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
					plugins: ['AMap.AutoComplete', 'AMap.Geocoder', 'AMap.MoveAnimation'],
				})
			).then((AMap) => {
				this.$emit('load', AMap)
				return AMap
			})
		},
		initMap() {
			this.loadMap()
				.then((AMap) => {
					// 实例化地图
					this.$map = new AMap.Map(this.$refs.mapBoxRef, {
						center: [this.lng, this.lat],
						zoom: 12,
					})
					this.$emit('ready', this.$map)

					// 搜索
					this.$autoInput = new AMap.AutoComplete({
						input: this.$refs.autoInputRef,
						output: 'inpt', //自定义展示框 解决z-index问题。 由于默认z-index较小，所以无法在element弹窗和抽屉组件中正常使用
					})
					console.log(this.$autoInput)

					this.$autoInput.on('select', ({ poi }) => {
						debugger
						/**
							adcode: "610113"
							address: "雁塔南路附近"
							city: []
							district: "陕西省西安市雁塔区"
							id: "B001D023F0"
							location: pD {className: 'AMap.LngLat', kT: 34.218229, KL: 108.96417600000001, lng: 108.964176, lat: 34.218229, …}
							name: "大雁塔"
							typecode: "110200"
						*/
						if (!poi.location) return
						const point = [poi.location.lng, poi.location.lat]
						if (this.$map) this.$map.setCenter(point)
						if (this.$marker)
							this.$marker.moveTo(point, {
								duration: 300,
								autoRotation: false,
							})
						this.$emit('select', { lng: point[0], lat: point[1] })
					})

					// 点标记
					this.$marker = new AMap.Marker({
						position: new AMap.LngLat(this.lng, this.lat),
						draggable: true,
						anchor: 'bottom-center',
					})
					this.$marker.on('dragend', (e) => {
						let point = this.$marker.getPosition()
						this.$map.setCenter(point)
						this.togglePointChange(point)
					})
					this.$marker.on('moveend', (e) => {
						this.togglePointChange(e.pos)
					})

					this.$map.on('moveend', (e) => {
						// 当点超出地图边界
						if (!this.$map.getBounds().contains(this.$marker.getPosition())) {
							this.$marker.moveTo(this.$map.getCenter(), {
								duration: 300,
								autoRotation: false,
							})
						}
					})
					this.$map.add(this.$marker)
				})
				.catch((errMsg) => {
					this.$emit('error', new Error(errMsg))
				})
		},
		togglePointChange(point: { lng: string | number; lat: string | number }) {
			this.$emit('update:lng', point.lng)
			this.$emit('update:lat', point.lat)
			this.$emit('change', point)
		},
	},
	mounted() {
		this.initMap()
	},
})
