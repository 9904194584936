<template>
	<div class="vi-home p-4">
		<el-form label-width="100px">
			<el-form-item label="地图选点">
				<AmapPicker class="mt-2" :lng.sync="map_data.lng" :lat.sync="map_data.lat" @change="onPointChange" />
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
import AmapPicker, { getDefaultLngLat } from '@/components/amap/amap-picker.vue'
export default {
	components: { AmapPicker },
	data() {
		return {
			map_data: getDefaultLngLat(),
		}
	},
	methods: {
		onPointChange() {
			console.log('经纬度变化', { ...this.map_data })
		},
	},
}
</script>
<style scoped lang="scss">
.vi-home {
}
</style>
